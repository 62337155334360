import { Col, List, Row, Spin, Breadcrumb } from 'antd';
import React, { useCallback, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { get } from 'lodash';
import NavigateNext from '@material-ui/icons/NavigateNext';

import { CollegeFinderContext } from './context';
import CollegeFinderFilters from './filters';
import {
  StyledUrl,
  TitleRow,
  TitleCol,
  StyledLink,
  StyledP,
  StyledLabel,
  StyledPagination,
  StyledDivider,
  StyledContainer,
  StyledMessage,
} from './styles';

import { formatPhoneNumber } from '../../utils/common';
import { Pagination, PageChangeEvent, DEFAULT_PAGE_SIZE } from '../../components/table/pagination';
import { University } from '../../types/models/college-finder';
import { NavigationWrapper } from '../../components/common/page-header';
import { useQuery } from '../../hooks/use-query';
import { searchUniversities } from '../../apis/public/college-finder';

const NO_SEARCH_RESULTS_MESSAGE = `Your search returned no results, please clear your filters and broaden your criteria. Only institutions
matching all criteria in your search will be returned.`;

const CollegeFinderList = (_: RouteComponentProps) => {
  const renderComma = (item: string) => (item !== null && item !== ' ' ? item + ', ' : '');

  const context = useContext(CollegeFinderContext);
  const pageNumber = context?.filters?.offset ? context?.filters?.offset / DEFAULT_PAGE_SIZE + 1 : 1;

  const { data, isLoading, error } = useQuery(
    [searchUniversities.QUERY_KEY, { filters: context.filters }],
    ({ queryKey }: any) => searchUniversities(queryKey[1].filters),
    {
      initialData: { universities: [], totalCount: 0 },
    }
  );

  const universities: University[] = get(data, 'universities', []);
  const total = get(data, 'totalCount', 0);

  const onPageChange = useCallback(
    (event: PageChangeEvent) => {
      context.setFilters({ offset: event.offset });
    },
    [context]
  );

  const generateBreadcrumb = (name?: string) => {
    return [
      <Link key="1" to="/">
        {name}
      </Link>,
      'Resources: College Finder',
    ];
  };

  const renderPagination = useCallback(() => {
    return (
      <StyledPagination>
        <Pagination current={pageNumber} total={total} onChange={onPageChange} />
      </StyledPagination>
    );
  }, [onPageChange, pageNumber, total]);

  const renderList = useCallback(() => {
    return (
      <Spin spinning={isLoading}>
        {error && <StyledMessage>{error.message}</StyledMessage>}
        {!isLoading && !data?.totalCount && <StyledMessage>{NO_SEARCH_RESULTS_MESSAGE}</StyledMessage>}
        {!error && (
          <>
            {renderPagination()}
            <StyledDivider />
            <List
              itemLayout="vertical"
              size="large"
              dataSource={universities}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Row>
                    <div style={{ marginRight: '15px' }}>
                      {item.image ? <img width={272} alt="college" src={item.image} /> : null}
                    </div>
                    <List.Item.Meta
                      title={
                        <div>
                          <StyledLink href={`/college-finder/${item.id}`} target="_blank" rel="noopener noreferrer">
                            {item.schoolName}
                          </StyledLink>
                        </div>
                      }
                      description={
                        <div>
                          <StyledLabel>{item.schoolType}</StyledLabel>
                          <StyledP>
                            {`${renderComma(item.street)}${renderComma(item.city)}${item?.state ?? ''} ${
                              item?.zipcode ?? ''
                            }`}
                          </StyledP>
                          <StyledP>{formatPhoneNumber(item?.phone)}</StyledP>
                          <StyledUrl href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.url}
                          </StyledUrl>
                        </div>
                      }
                    />
                  </Row>
                </List.Item>
              )}
            />
            {total ? <StyledDivider /> : null}
            {renderPagination()}
          </>
        )}
      </Spin>
    );
  }, [data, error, isLoading, renderPagination, total, universities]);

  return (
    <StyledContainer>
      <NavigationWrapper>
        <Breadcrumb separator={<NavigateNext />}>
          {generateBreadcrumb('Home').map((item, index) => (
            <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </NavigationWrapper>
      <TitleRow>
        <TitleCol>College Finder</TitleCol>
      </TitleRow>
      <Row justify="space-between">
        <Col xs={24}>
          <CollegeFinderFilters />
          {renderList()}
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default CollegeFinderList;
